module WinJS.Binding.Scope {


    function _getClosestBySelector(element: HTMLElement, selector: string) {

        var firstChar = selector.charAt(0);

        // Get closest match
        for (; element && element !== document.body; element = <HTMLElement>element.parentNode) {

            // If selector is a class
            if (firstChar === '.') {
                if (element.classList.contains(selector.substr(1))) {
                    return element;
                }
            }

            // If selector is an ID
            if (firstChar === '#') {
                if (element.id === selector.substr(1)) {
                    return element;
                }
            }

            // If selector is a data attribute
            if (firstChar === '[') {
                if (element.hasAttribute(selector.substr(1, selector.length - 2))) {
                    return element;
                }
            }

            // If selector is a tag
            if (element.tagName.toLowerCase() === selector) {
                return element;
            }
        }

        return undefined;
    };

    function _getClosestByFunction(element: HTMLElement, match: (element: HTMLElement) => boolean) {


        // Get closest match
        for (; element && element !== document.body; element = <HTMLElement>element.parentNode) {
            // If selector is a class
            if (match(element)) {
                return element;
            }
        }

        return undefined;
    };



    export var window = WinJS.Binding.initializer(function (source, sourceProps, dest, destProps) {
        WinJS.Binding.defaultBind(window, sourceProps, dest, destProps);
    });

    export var page = WinJS.Binding.initializer(function (source, sourceProps, dest, destProps) {
        var page = document.querySelector('.pagecontrol');

        WinJS.Binding.defaultBind(page.winControl, sourceProps, dest, destProps);
    });

    export var context = WinJS.Binding.initializer(function (source, sourceProps, dest, destProps) {
        var page = document.querySelector('.pagecontrol');
        var wincontrol = page.winControl;
        var context = wincontrol.context;

        WinJS.Binding.defaultBind(context, sourceProps, dest, destProps);
    });


    export var template = WinJS.Binding.initializer(function (source, sourceProps, dest, destProps) {
        

        var template = (<any>document.querySelector('#' + sourceProps[0]));

        sourceProps.shift();

        WinJS.Binding.defaultBind(template.winControl, sourceProps, dest, destProps);
    });
}


module WinJS.Binding.Mode {

    export var twoway = WinJS.Binding.initializer(function (source, sourceProps, dest, destProps) {
        WinJS.Binding.defaultBind(source, sourceProps, dest, destProps);
        dest.onchange = function () {
            var d = dest[destProps[0]];

            var s = source;
            for (var i = 0; i < sourceProps.length - 1; i++)
                s = s[sourceProps[i]];

            if (s !== d)
                s[sourceProps[sourceProps.length - 1]] = d;
        }
    });

    export var es6 = WinJS.Binding.initializer(function (source, sourceProps, dest, destProps) {
        var eventSource = dest;
        var command = source;
        var sourceItems = destProps.length;
        var destItems = sourceProps.length;
        dest[destProps[0]] = source[sourceProps[0]];
    });

    export var command = WinJS.Binding.initializer(function (source, sourceProps, dest, destProps) {
        //sourceProps.push("execute");
        var eventSource = dest;
        var command = source;
        var sourceItems = destProps.length;
        var destItems = sourceProps.length;

        for (var i = 0; i < sourceItems - 1; i++) {
            eventSource = eventSource[destProps[i]];
        }

        for (var x = 0; x < destItems; x++) {
            command = command[sourceProps[x]];
        }

        //Subscribes the event
        eventSource[destProps[sourceItems - 1]] = function () {
            //if (!WinJS.Utilities.hasClass("disabled")) {
                command["execute"].call(source, dest);
            //}
        };

        //monitors canExecute
        command["canExecute"].bind("value", function (isEnabled) {
            var sourceType = eventSource.tagName.toLowerCase();
            if (isEnabled)
                WinJS.Utilities.removeClass(eventSource, "disabled");
            else
                WinJS.Utilities.addClass(eventSource, "disabled");


            if (sourceType == "button") {
                eventSource.disabled = !isEnabled;
            }

        });
    })

}


module WinJS.Binding.Transform {

    function _getValue(obj, path) {
        if (path) {
            for (var i = 0, len = path.length; i < len && (obj !== null && obj !== undefined); i++) {
                obj = obj[path[i]];
            }
        }
        return obj;
    }

    function _putValue(obj, path, newValue) {
        if (path) {
            var i = 0;
            for (; i < (path.length - 1) && (obj !== null && obj !== undefined); i++) {
                obj = obj[path[i]];
            }

            if (obj) {
                obj[path[i]] = newValue;
            }
        }
    }



    export var addclass = WinJS.Binding.initializer(function (source, sourceProperty, dest, destProperty) {
        var func = function () {
            WinJS.Utilities.addClass(dest, _getValue(source, sourceProperty));
        };
        return WinJS.Binding.bind(source, { sourceProperty: func });
    });

    export var removeclass = WinJS.Binding.initializer(function (source, sourceProperty, dest, destProperty) {
        var func = function () {
            WinJS.Utilities.removeClass(dest, _getValue(source, sourceProperty));
        };
        return WinJS.Binding.bind(source, { sourceProperty: func });
    });
}


module WinJS.Binding.Converter {

    export var booltovisibility = WinJS.Binding.converter(function (bool) {
        return bool ? "block" : "none";
    });

    export var nulltovisibility = WinJS.Binding.converter(function (value) {
        return value != null ? "block" : "none";
    });

}